import { Grid } from "@mui/material";
import { ReactNode } from "react";
import { GameTimeForm } from "src/components/pages/owner/setting/game_time_form";
import { TableNumberForm } from "src/components/pages/owner/setting/table_number_form";
import { useRealtimeDatabase } from "src/firebase/realtime_database/hooks";
import { IzakayaRealTimeDataBaseData } from "src/firebase/realtime_database/type";

/**
 * only for creating DRY grid.
 */
const SettingGrid = (props: { children: ReactNode }) => {
  return (
    <Grid xl={4} padding={2} sm={6} xs={12}>
      {props.children}
    </Grid>
  );
};

export const OwnerSettingPage = () => {
  const configData =
    useRealtimeDatabase<IzakayaRealTimeDataBaseData["config"]>("config");

  return (
    <Grid container padding={1} width={"100%"}>
      <SettingGrid>
        <TableNumberForm tableNumber={configData?.roomAmount} />
      </SettingGrid>
      <SettingGrid>
        <GameTimeForm gameTimeMinutes={configData?.gameTimeMinutes} />
      </SettingGrid>
    </Grid>
  );
};
