import { Tab, Tabs } from "@mui/material";
import Box from "@mui/material/Box";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  OWNER_SETTING_PAGE_FULL_PATH,
  OWNER_TABLE_PAGE_FULL_PATH,
} from "src/components/pages/paths";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export type OwnerHeaderProps = {
  height?: string;
};

export const OwnerHeader = (props: OwnerHeaderProps) => {
  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const navigate = useNavigate();

  return (
    <Box sx={{ width: "100%", height: props.height ?? "auto" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          allowScrollButtonsMobile
          scrollButtons="auto"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            label="Tables"
            {...a11yProps(0)}
            onClick={() => {
              navigate(`${OWNER_TABLE_PAGE_FULL_PATH}`);
            }}
          />
          <Tab
            label="Settings"
            {...a11yProps(1)}
            onClick={() => {
              // navigate(`${OWNER_TABLE_PAGE_PATH}`);
              navigate(`${OWNER_SETTING_PAGE_FULL_PATH}`);
            }}
          />
        </Tabs>
      </Box>
    </Box>
  );
};
