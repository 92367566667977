/**
 * @link https://firebase.google.com/docs/database/web/start?hl=ja
 */

// Import the functions you need from the SDKs you need
import { getApp, getApps, initializeApp } from "firebase/app";
import "firebase/compat/database";
import { getDatabase } from "firebase/database";
import { FIREBASE_CONFIG } from "src/firebase/config";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// Initialize Firebase
export const firebaseApp = initializeApp(FIREBASE_CONFIG);
export const firebaseDatabase = getDatabase(firebaseApp);

/**
 * @link https://zenn.dev/hisho/books/617d8f9d6bd78b/viewer/chapter4
 */
export const initializeFirebaseApp = () =>
  !getApps().length ? initializeApp(FIREBASE_CONFIG) : getApp();
