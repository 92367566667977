/**
 * type definition and static data about order.
 */

import { getDatabase, ref, set } from "@firebase/database";
import { GameID } from "src/core/game";

/**
 * sloppy status.
 */
export type ProblemStatus = "unsolved" | "solved" | "serving" | "skipped";

export type ProblemID = number;

export type MenuCategory =
  | "strong-alcohol"
  | "weak-alcohol"
  | "food"
  | "speed"
  | "recommend"
  | "dessert"
  | "finish";

/**
 * problem dynamic data in realtime database.
 */
export type RealtimeProblem = {
  status: ProblemStatus;
  id: number;
  category: MenuCategory;
};

export type ProblemDefinition = {
  answer: string[];
  category: MenuCategory;
  id: ProblemID;
  status: ProblemStatus;
  score: number;
  alcoholLevel: number;
};

/**
 * Set problem data to realtime database.
 * @param gameID
 * @param problemID
 * @param data
 * @returns
 */
export const upsertRealtimeProblem = (
  gameID: GameID,
  problemID: ProblemID,
  data: Partial<ProblemDefinition>
) => {
  const db = getDatabase();
  return set(ref(db, `game/${gameID}/problem/${problemID}`), data);
};
