import { SnackbarProps } from "@mui/material";
import { ReactNode } from "react";
import { atom } from "recoil";
import { RealtimeDataBaseGame } from "src/firebase/realtime_database/type";

export const modalComponent = atom<ReactNode>({
  key: "modalComponent",
  default: <></>,
});

export const modalSwitch = atom({
  key: "modalSwitch",
  default: false,
});

export const gameHeaderIndex = atom({
  key: "gameHeaderIndex",
  default: 0,
});

export const ownerHeaderIndex = atom({
  key: "ownerHeaderIndex",
  default: 0,
});

export const snackBarSwitch = atom<SnackbarProps>({
  key: "snackBarSwitch",
  default: {
    open: false,
  },
});

export const realTimeGameData = atom<Partial<RealtimeDataBaseGame>>({
  key: "gameData",
  default: {},
});
