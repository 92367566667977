import { Box } from "@mui/material";

export const createGamePagePath = (id: string) => `/game/${id}`;

export const MenuPage = () => {
  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <img style={{ maxWidth: "90%" }} src="/images/menu.png"></img>
      </div>
    </Box>
  );
};
