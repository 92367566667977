import { Box, Grid } from "@mui/material";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { AnswerForm } from "src/components/organisms/answer_form/answer_form";
import { useNotifier } from "src/components/organisms/context_snackbar/context_snackbar";
import { eatUpFood } from "src/core/game";
import { ProblemID } from "src/core/problem";
import { PROBLEMS, PROBLEM_DICT } from "src/data/problem_data";
import { realTimeGameData } from "src/store/recoil_state";

export const createProblemAnswerPagePath = (id: string) =>
  `/game/${id}/problem`;

export const ProblemAnswerPage = () => {
  const { id } = useParams();
  const gameData = useRecoilValue(realTimeGameData);

  const notifier = useNotifier();
  return (
    <Box>
      <AnswerForm
        foodOrDrink={"food"}
        onAnswer={(answer, problemID, setAnswer, setProblem) => {
          if (problemID >= 500) {
            notifier.notifyError("お酒はドリンクのタブから回答してください！");
            return;
          }
          const targetProblems = PROBLEMS.filter((problem) => {
            return problem.id === problemID;
          });
          if (targetProblems.length === 0) {
            notifier.notifyError(
              "該当の問題が見つかりませんでした...店員を呼んでください。"
            );
          } else {
            const targetProblem = targetProblems[0];
            if (gameData.problem?.[targetProblem.id].status === "solved") {
              notifier.notifyError("すでに完食済みのフードです...");
              return;
            }
            if (targetProblem.answer.includes(answer) && id !== undefined) {
              notifier.notifySuccess("正解です！");
              eatUpFood(id, targetProblem.id);
              setAnswer(undefined);
              setProblem(undefined);
              console.log("eatUpFood", targetProblem.id, id);
            } else {
              notifier.notifyError("不正解です...");
            }
          }
        }}
      />
      <SolvedProblems />
    </Box>
  );
};

const SolvedProblems = () => {
  const gameData = useRecoilValue(realTimeGameData);

  const solvedProblems: ProblemID[] = useMemo(() => {
    return Object.keys(gameData?.problem ?? {}).filter((problemID) => {
      const problem = gameData?.problem?.[problemID];
      return problem?.status === "solved";
    });
  }, [gameData]);

  return (
    <Box p={3}>
      <h3>食べた/飲んだ 商品</h3>
      <Grid container spacing={2}>
        {solvedProblems.map((problemID) => {
          return (
            <Grid item xs={6} key={problemID} lg={3} md={3}>
              {problemID} {PROBLEM_DICT[problemID].answer[0]}
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
