import { Outlet } from "react-router-dom";
import { AdjustHeight } from "src/components/molecules/adjust_height/adjust_height";
import { OwnerHeader } from "src/components/organisms/owner_header/owner_header";

/**
 * for react-router.
 */
export const OwnerHeaderTemplate = () => {
  return (
    <AdjustHeight
      TopComponent={<OwnerHeader />}
      BottomComponent={<Outlet />}
      base="top"
    />
  );
};
