import { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GameID } from "src/core/game";
import { useRealtimeDatabase } from "src/firebase/realtime_database/hooks";
import { IzakayaRealTimeDataBaseData } from "src/firebase/realtime_database/type";

export const TablePage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const query = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);

  const tableID = useMemo(() => {
    const queryTableID = query.get("tableID");
    if (queryTableID === null) {
      return undefined;
    } else {
      return Number(queryTableID);
    }
  }, [query]);

  const tableData =
    useRealtimeDatabase<IzakayaRealTimeDataBaseData["table"]>("table");
  const path = useMemo(() => {
    return `game/${tableID !== undefined ? tableData?.at(tableID)?.gameID : "aaa"}/status`;
  }, [tableData, tableID]);

  const gameStatusData =
    useRealtimeDatabase<IzakayaRealTimeDataBaseData["game"][GameID]["status"]>(
      path
    );

  useEffect(() => {
    if (
      tableID !== undefined &&
      gameStatusData?.progress !== "finished" &&
      gameStatusData?.progress !== undefined
    ) {
      if (tableData?.at(tableID)) {
        const gameID = tableData[tableID]?.gameID;
        navigate(`/game/${gameID}`);
      }
    }
  }, [
    gameStatusData,
    gameStatusData?.progress,
    navigate,
    path,
    tableData,
    tableID,
  ]);

  if (tableID === null) {
    return (
      <div>
        <div>tableID is not found</div>
      </div>
    );
  }
  return (
    <div>
      <div>まだテーブルの準備ができていないようです。少々お待ちください。</div>
    </div>
  );
};
