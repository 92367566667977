import { Snackbar, SnackbarContent, useTheme } from "@mui/material";
import { ReactNode, useMemo } from "react";
import { useRecoilState } from "recoil";
import { snackBarSwitch } from "src/store/recoil_state";

export type SnackBarContextProps = {
  children: ReactNode;
};

export const SnackBarContext = (props: SnackBarContextProps) => {
  const [snackBar, setSnackBar] = useRecoilState(snackBarSwitch);

  return (
    <>
      {props.children}
      <Snackbar
        // key={index}

        open={true}
        onClose={() => {
          setSnackBar({
            open: false,
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
        }}
        autoHideDuration={3000}
        //   onClose={handleClose}
        {...snackBar}
      />

      {/* {snackBar.map((snackBar, index) => (
      ))}
    </> */}
    </>
  );
};

export const useNotifier = () => {
  const [, setSnackBar] = useRecoilState(snackBarSwitch);
  const theme = useTheme();
  const snackBarController = useMemo(() => {
    return {
      notifySuccess: (message: string) => {
        setSnackBar({
          open: true,
          anchorOrigin: { vertical: "top", horizontal: "right" },
          autoHideDuration: 2000,
          children: (
            <SnackbarContent
              sx={{
                backgroundColor: theme.palette.success.main,
              }}
              message={message}
            />
          ),
        });
      },
      notifyError: (message: string) => {
        setSnackBar({
          open: true,
          anchorOrigin: { vertical: "top", horizontal: "right" },
          autoHideDuration: 2000,
          children: (
            <SnackbarContent
              sx={{
                backgroundColor: theme.palette.error.main,
              }}
              message={message}
            />
          ),
        });
      },
    };
  }, [setSnackBar, theme.palette.error.main, theme.palette.success.main]);
  return snackBarController;
};
