import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { CustomerPage } from "src/components/pages/game/customer/customer_page";
import { MenuPage } from "src/components/pages/game/game_menu_page";
import { ProblemAnswerPage } from "src/components/pages/game/problem/game_problem_page";
import { HomePage } from "src/components/pages/home/home_page";
import { NotFoundPage } from "src/components/pages/not_found/not_found_page";
import { OwnerGameProblemPage } from "src/components/pages/owner/problem/owner_game_problem_page";
import { OwnerSettingPage } from "src/components/pages/owner/setting/onwer_setting_page";
import { OwnerTablePage } from "src/components/pages/owner/table/onwer_table_page";
import { OwnerTableDetailPage } from "src/components/pages/owner/table_detail_page/table_detail_page";
import {
  OWNER_GAME_PROBLEM_PAGE_PARTIAL_PATH,
  OWNER_PAGE_PATH,
  OWNER_SETTING_PAGE_PARTIAL_PATH,
  OWNER_TABLE_DETAIL_PAGE_FULL_PATH,
  OWNER_TABLE_PAGE_PARTIAL_PATH,
  TABLE_PAGE_PATH,
} from "src/components/pages/paths";
import { TablePage } from "src/components/pages/table/table_page";
import { GameTemplate } from "src/components/templates/game_header_template/game_header_template";
import { OwnerHeaderTemplate } from "src/components/templates/owner_header_template/owner_header_template";
export const AppRoute: React.FC = () => {
  const [rootHeight, setRootHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      // alert(window.innerHeight)
      setRootHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);


    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  return (
    <div style={{
      height: rootHeight
    }}>

      <Routes>
        <Route path="/" element={<HomePage />} />
        {/* game */}
        <Route path={`/game`} element={<GameTemplate />}>
          <Route path="/game/:id/" element={<MenuPage />} />
          <Route path="/game/:id/problem" element={<ProblemAnswerPage />} />
          {/* <Route path="/game/:id/qr_code" element={<QRCodePage />} /> */}
          <Route path="/game/:id/customer" element={<CustomerPage />} />
        </Route>
        {/* owner */}
        <Route path={`${OWNER_PAGE_PATH}`} element={<OwnerHeaderTemplate />}>
          {/* <Route path={`/${OWNER_PAGE_PATH}`} element={<OwnerPage />} /> */}
          <Route
            path={`${OWNER_TABLE_PAGE_PARTIAL_PATH}`}
            element={<OwnerTablePage />}
          />
          <Route
            path={`${OWNER_GAME_PROBLEM_PAGE_PARTIAL_PATH}`}
            element={<OwnerGameProblemPage />}
          />
          <Route
            path={`${OWNER_SETTING_PAGE_PARTIAL_PATH}`}
            element={<OwnerSettingPage />}
          />
        </Route>
        <Route
          path={`${OWNER_TABLE_DETAIL_PAGE_FULL_PATH}`}
          element={<OwnerTableDetailPage />}
        />
        {/* table */}
        <Route path={`${TABLE_PAGE_PATH}`} element={<TablePage />} />

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
};
