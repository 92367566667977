import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useMemo } from "react";
import { useRealtimeDatabase } from "src/firebase/realtime_database/hooks";
import { IzakayaRealTimeDataBaseData } from "src/firebase/realtime_database/type";
import { A11y, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

export const Ranking = () => {
  const rankingData =
    useRealtimeDatabase<IzakayaRealTimeDataBaseData["ranking"]>("ranking");

  const systemMaxMember =
    useRealtimeDatabase<IzakayaRealTimeDataBaseData["config"]["maxMember"]>(
      "config/maxMember"
    );

  const members = useMemo(() => {
    return [...Array(systemMaxMember ?? 0)].map((_, i) => i + 1);
  }, [systemMaxMember]);

  return (
    <div style={{ width: "100%", maxWidth: "600px" }}>
      <Swiper
        modules={[Pagination, A11y, Navigation]}
        spaceBetween={20}
        slidesPerView={1}
        navigation={true}
        noSwiping={true}
        pagination={{ clickable: true, type: "fraction" }}
        style={{
          margin: 0,
          padding: 0,
          marginTop: "20px",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {members.map((memberAmount) => {
          return (
            <SwiperSlide
              key={memberAmount}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <RankingInFixedMember
                rankingData={rankingData}
                memberAmount={memberAmount}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

const RankingInFixedMember = (props: {
  memberAmount: number;
  rankingData?: Partial<IzakayaRealTimeDataBaseData["ranking"]>;
}) => {
  const rows = Object.entries(
    props.rankingData?.[props.memberAmount] ?? {}
  ).map(([key, value]) => {
    return {
      key: value?.teamName,
      value: value?.score,
      id: key,
    };
  });

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: "key",
        headerName: `チーム(${props.memberAmount}人)`,
        flex: 1,
      },
      { field: "value", headerName: "スコア", width: 100, flex: 0 },
    ],
    [props.memberAmount]
  );

  return (
    <DataGrid
      density="compact"
      rowHeight={40}
      rows={rows}
      columns={columns}
      pageSizeOptions={[10]}
      disableColumnFilter
      disableColumnMenu
      hideFooter
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 10,
          },
        },
        sorting: {
          sortModel: [{ field: "value", sort: "desc" }],
        },
      }}
    />
  );
};
