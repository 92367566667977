import { getDatabase, off, onValue, ref } from "firebase/database";
import { useEffect, useMemo, useState } from "react";

/**
 *
 * @param path
 * @returns
 */
export const useRealtimeDatabase = <T>(
  path?: string
): Partial<T> | undefined => {
  const db = useMemo(() => getDatabase(), []);
  const reserveRef = useMemo(
    () => (path ? ref(db, `${path}`) : undefined),
    [db, path]
  );
  const [data, setData] = useState<T | undefined>(undefined);

  useEffect(() => {
    if (!reserveRef) return;

    onValue(reserveRef, (snapshot) => {
      if (snapshot?.val()) {
        setData(snapshot.val());
      } else {
        setData(undefined);
      }
    });
    return () => {
      off(reserveRef);
    };
  }, [path, reserveRef]);
  return data;
};
