import { Button, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useModal } from "src/components/organisms/context_modal/context_modal";

export type GameTimeFormProps = {
  gameTimeMinutes?: number;
};

/**
 *
 */
export const GameTimeForm = (props: GameTimeFormProps) => {
  const [gameTime, setGameTime] = useState(1);
  const modalController = useModal();

  useEffect(() => {
    if (props.gameTimeMinutes !== undefined) {
      setGameTime(props.gameTimeMinutes);
    }
  }, [props.gameTimeMinutes]);

  return (
    <div style={{ width: "100%" }}>
      <TextField
        disabled={false}
        size="small"
        type="number"
        label="ゲーム時間(分)"
        placeholder="ゲーム時間(分)"
        value={gameTime}
        onChange={(e) => {
          setGameTime(Number(e.target.value));
        }}
        prefix="分"
        // 115 px is the width we can see all text.
        sx={{ minWidth: "115px", width: "50%" }}
      />
      <Button
        style={{
          // sloppy margin...
          right: "20px",
          position: "absolute",
        }}
        variant="contained"
        onClick={() => {
          modalController.open(
            <div>
              <p>変更しますか？</p>
              <Button variant="contained">はい</Button>
            </div>
          );
        }}
      >
        変更
      </Button>
    </div>
  );
};
