import { child, get, getDatabase, ref, set } from "@firebase/database";
import {
  MenuCategory,
  ProblemID,
  upsertRealtimeProblem,
} from "src/core/problem";
import { PROBLEMS, PROBLEM_DICT } from "src/data/problem_data";
import { RealtimeDataBaseGame } from "src/firebase/realtime_database/type";

export type GameID = string;
export type TeamID = string;

export const initializeGame = (
  gameID: GameID,
  memberAmount: number,
  gameTimeMinutes: number
) => {
  // initializeOrder(gameID, memberAmount);
  initializeGameProblemData(gameID);
  initializeGameConfig(gameID, memberAmount, gameTimeMinutes);
  initializeGameStatus(gameID, memberAmount);
  // initializeGameOrder(gameID, memberAmount);
};

const initializeGameStatus = (gameID: GameID, memberAmount: number) => {
  const db = getDatabase();
  const gameConfigSetting: RealtimeDataBaseGame["status"] = {
    drunkennessLevel: memberAmount === 1 ? 20 : 0,
    score: 0,
    progress: "waiting",
  };
  set(ref(db, `game/${gameID}/status`), gameConfigSetting);
};

const initializeGameProblemData = (gameID: GameID) => {
  PROBLEMS.forEach((problem) => {
    upsertRealtimeProblem(gameID, problem.id, {
      status: "unsolved",
    });
  });
};

const initializeGameConfig = (
  gameID: GameID,
  memberAmount: number,
  gameTimeMinutes: number
) => {
  const db = getDatabase();
  const gameConfigSetting: Partial<RealtimeDataBaseGame["config"]> = {
    memberAmount: memberAmount,
    startAt: Date.now(),
    gameTimeMinutes: gameTimeMinutes,
  };
  set(ref(db, `game/${gameID}/config`), gameConfigSetting);
};

/**
 * TODO 毎回全問題のデータをfeedしているので、結構もったいないです
 */
export const orderAlcohol = async (
  gameID: GameID,
  customerID: number,
  alcoholType: MenuCategory,
  targetIndex: number
) => {
  // get problem data from realtime database.
  // alert(`start`);
  const databaseRef = ref(getDatabase());
  // alert(`start 2`);
  const snapshot = await get(child(databaseRef, `game/${gameID}/problem`));
  // alert(`aaa ${snapshot.val()}`);

  if (snapshot.exists()) {
    const problems: RealtimeDataBaseGame["problem"] = snapshot.val();
    const targetProblems = Object.keys(problems).filter((key) => {
      return (
        PROBLEM_DICT[key].category === alcoholType &&
        problems[key].status === "unsolved"
      );
    });
    // random pickup.
    const randomIndex = targetIndex % targetProblems.length;
    // alert(`randomIndex ${randomIndex}, ${targetProblems[randomIndex]}`);
    const problemToBeServed = PROBLEM_DICT[targetProblems[randomIndex]];
    console.log("problemToBeServed: ", problemToBeServed);
    // update
    const db = getDatabase();
    set(
      ref(db, `game/${gameID}/problem/${problemToBeServed.id}/status`),
      "serving"
    ).then(() => {
      console.log("serving");
    });
    set(
      ref(db, `game/${gameID}/order/${customerID}/alcohol`),
      problemToBeServed.id
    );
  }
};

export const drinkUpAlcohol = (
  gameID: GameID,
  customerID: number,
  drinkID: ProblemID,
  alcoholLevel: number
) => {
  // update
  const db = getDatabase();
  set(ref(db, `game/${gameID}/order/${customerID}/alcohol`), null);
  set(ref(db, `game/${gameID}/problem/${drinkID}/status`), "solved");
  const databaseRef = ref(getDatabase());
  get(child(databaseRef, `game/${gameID}/status/drunkennessLevel`)).then(
    (snapshot) => {
      if (snapshot.exists()) {
        const drunkennessLevel = snapshot.val();
        set(
          ref(db, `game/${gameID}/status/drunkennessLevel`),
          Math.min(drunkennessLevel + alcoholLevel, 140)
        );
      }
    }
  );
  get(child(databaseRef, `game/${gameID}/status/score`)).then((snapshot) => {
    if (snapshot.exists()) {
      const score = snapshot.val();
      set(ref(db, `game/${gameID}/status/score`), score + 1);
    }
  });
};

export const cancelAlcohol = (
  gameID: GameID,
  customerID: number,
  drinkID: ProblemID
) => {
  // update
  const db = getDatabase();
  set(ref(db, `game/${gameID}/order/${customerID}/alcohol`), null);
  set(ref(db, `game/${gameID}/problem/${drinkID}/status`), "unsolved");
};

export const skipAlcohol = (
  gameID: GameID,
  customerID: number,
  drinkID: ProblemID
) => {
  // update
  const db = getDatabase();

  set(ref(db, `game/${gameID}/order/${customerID}/alcohol`), null);
  set(ref(db, `game/${gameID}/problem/${drinkID}/status`), "skipped");
};

export const eatUpFood = (gameID: GameID, foodID: ProblemID) => {
  // update
  const db = getDatabase();
  const databaseRef = ref(getDatabase());

  set(ref(db, `game/${gameID}/problem/${foodID}/status`), "solved");
  get(child(databaseRef, `game/${gameID}/status/drunkennessLevel`)).then(
    (snapshot) => {
      if (snapshot.exists()) {
        const gameTimeMinutes = snapshot.val();
        console.warn("gameTimeMinutes: ", gameTimeMinutes);
        set(
          ref(db, `game/${gameID}/status/drunkennessLevel`),
          gameTimeMinutes - 9
        );
      } else {
        console.warn("No data available");
      }
    }
  );
};

export const setGamePassword = (gameID: GameID, password: string) => {
  const db = getDatabase();

  set(ref(db, `game/${gameID}/config/password`), password);
};

export const extendGameTime = (gameID: GameID, timeSecond: number) => {
  // update
  const db = getDatabase();
  const databaseRef = ref(getDatabase());
  get(child(databaseRef, `game/${gameID}/config/gameTimeMinutes`)).then(
    (snapshot) => {
      if (snapshot.exists()) {
        const gameTimeMinutes = snapshot.val();
        console.warn("gameTimeMinutes: ", gameTimeMinutes);
        set(
          ref(db, `game/${gameID}/config/gameTimeMinutes`),
          gameTimeMinutes + timeSecond / 60
        );
      } else {
        console.warn("No data available");
      }
    }
  );
};

export const startGame = (gameID: GameID, gameTimeMinutes: number) => {
  // update
  const db = getDatabase();
  console.log("gameTimeMinutes: ", gameTimeMinutes, gameID);
  set(ref(db, `game/${gameID}/config/gameTimeMinutes`), gameTimeMinutes);
  set(ref(db, `game/${gameID}/config/startAt`), Date.now());
  set(ref(db, `game/${gameID}/status/progress`), "playing");
};

const updateScore = (
  gameID: GameID,
  teamName: string,
  score: number,
  memberAmount: number
) => {
  // update
  const db = getDatabase();
  console.log("updateScore ", gameID, score);
  set(ref(db, `game/${gameID}/config/scoreRegistered`), true);
  const databaseRef = ref(getDatabase());
  get(child(databaseRef, `ranking`)).then((snapshot) => {
    if (snapshot.exists()) {
      const gameTimeMinutes = snapshot.val();
      console.warn("gameTimeMinutes: ", gameTimeMinutes);
      // TODO ここでランキングを確認する
      set(ref(db, `ranking/${memberAmount}/${gameID}/`), {
        score: score,
        teamName: teamName,
      });
    } else {
      set(ref(db, `ranking/${memberAmount}/${gameID}/`), score);
    }
  });
};

export const finishGame = (gameID: GameID) => {
  // update
  const db = getDatabase();
  const databaseRef = ref(getDatabase());
  get(child(databaseRef, `game/${gameID}/status/score`)).then(
    (scoreSnapShot) => {
      if (scoreSnapShot.exists()) {
        get(child(databaseRef, `game/${gameID}/config`)).then(
          (teamNameSnapshot) => {
            if (teamNameSnapshot.exists()) {
              const score = scoreSnapShot.val();
              const config: RealtimeDataBaseGame["config"] =
                teamNameSnapshot.val();
              updateScore(gameID, config.password, score, config.memberAmount);
            } else {
              console.warn(
                "No teamName available. can not update score ranking."
              );
            }
          }
        );
      } else {
        console.warn("No score available. can not update score ranking.");
      }
    }
  );
  set(ref(db, `game/${gameID}/status/progress`), "finished");
};
