export type AdjustHeightProps = {
  TopComponent: React.ReactNode;
  BottomComponent: React.ReactNode;
  base: "top" | "bottom";
};
export const AdjustHeight = (props: AdjustHeightProps) => {
  if (props.base === "top") {
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ height: "auto", flexGrow: 0 }}>{props.TopComponent}</div>
        <div style={{ flexGrow: 1 }}>{props.BottomComponent}</div>
      </div>
    );
  } else if (props.base === "bottom") {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <div></div>
        {props.TopComponent}
        {props.BottomComponent}
      </div>
    );
  } else {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        {props.TopComponent}
        {props.BottomComponent}
      </div>
    );
  }
};
