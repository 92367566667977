
export const HomePage = () => {
  // const navigate = useNavigate();

  return (
    <div>
      {/* <a
        onClick={() => {
          navigate("/owner/table");
        }}
      >
        店員ログイン画面 /owner
      </a> */}
    </div>
  );
};
