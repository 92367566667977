import { Tab, Tabs } from "@mui/material";
import Box from "@mui/material/Box";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { createGameCustomerPagePath } from "src/components/pages/game/customer/customer_page";
import { createGamePagePath } from "src/components/pages/game/game_menu_page";
import { createProblemAnswerPagePath } from "src/components/pages/game/problem/game_problem_page";
import { GameID } from "src/core/game";
import { useRealtimeDatabase } from "src/firebase/realtime_database/hooks";
import { IzakayaRealTimeDataBaseData } from "src/firebase/realtime_database/type";
import { gameHeaderIndex } from "src/store/recoil_state";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const fontStyle = {
  fontFamily: "Yuji Syuku, serif",
  fontSize: "1.1rem",
};

export const GameHeader = () => {
  const [headerIndex, setHeaderIndex] = useRecoilState(gameHeaderIndex);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setHeaderIndex(newValue);
  };

  const navigate = useNavigate();
  const { id } = useParams();
  const gameConfigData = useRealtimeDatabase<
    IzakayaRealTimeDataBaseData["game"][GameID]["config"]
  >(`game/${id}/config`);

  const memberArray = [...Array(gameConfigData?.memberAmount ?? 0)].map(
    (_, i) => i
  );
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={headerIndex}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            style={fontStyle}
            label="メニュー"
            onClick={() => {
              if (id) {
                navigate(createGamePagePath(id));
              }
            }}
          />
          <Tab
            style={fontStyle}
            label="フード回答"
            onClick={() => {
              if (id) {
                navigate(createProblemAnswerPagePath(id));
              }
            }}
          />
          {memberArray.map((table, index) => {
            return (
              <Tab
                style={fontStyle}
                key={index}
                label={`ドリンク ${index + 1}`}
                {...a11yProps(index + 2)}
                onClick={() => {
                  if (id) {
                    navigate(createGameCustomerPagePath(id, index));
                  }
                }}
              />
            );
          })}
        </Tabs>
      </Box>
    </Box>
  );
};
