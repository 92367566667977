import { Box } from "@mui/material";
import { useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import { BaseImage } from "src/components/atoms/image/image";
import { AnswerForm } from "src/components/organisms/answer_form/answer_form";
import { useNotifier } from "src/components/organisms/context_snackbar/context_snackbar";
import { convertToDrunkennessDegree } from "src/core/converter";
import { GameID, drinkUpAlcohol } from "src/core/game";
import { PROBLEMS } from "src/data/problem_data";
import { useRealtimeDatabase } from "src/firebase/realtime_database/hooks";
import { IzakayaRealTimeDataBaseData } from "src/firebase/realtime_database/type";

export const createGameCustomerPagePath = (id: string, customerID: number) =>
  `/game/${id}/customer?customerID=${customerID}`;

export const CustomerPage = () => {
  const notifier = useNotifier();
  const location = useLocation();
  const query = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const customerID = useMemo<number | null>(() => {
    const customerID = query.get("customerID");
    if (customerID === null) {
      return null;
    } else {
      return Number(customerID);
    }
  }, [query]);

  const { id } = useParams();

  const gameStatusData = useRealtimeDatabase<
    IzakayaRealTimeDataBaseData["game"][GameID]["status"]
  >(`game/${id}/status`);

  const gameOrderData = useRealtimeDatabase<
    IzakayaRealTimeDataBaseData["game"][GameID]["order"][number]
  >(`game/${id}/order/${customerID}`);

  const orderedDrinkID = useMemo(() => {
    if (customerID !== null && customerID !== undefined) {
      return gameOrderData?.alcohol;
    } else {
      return undefined;
    }
  }, [customerID, gameOrderData]);

  const drunkennessDegree = convertToDrunkennessDegree(
    gameStatusData?.drunkennessLevel ?? 0
  );

  const problemImagePath = useMemo(() => {
    console.log(orderedDrinkID, gameStatusData?.drunkennessLevel);
    if (
      orderedDrinkID === undefined ||
      gameStatusData?.drunkennessLevel === undefined
    ) {
      return undefined;
    } else {
      const paddedOrderDrinkID = String(orderedDrinkID).padStart(2, "0");

      return `/images/nazo/nazo_${paddedOrderDrinkID}-${drunkennessDegree}.png`;
    }
  }, [drunkennessDegree, gameStatusData?.drunkennessLevel, orderedDrinkID]);

  console.log(customerID, gameOrderData);

  if (problemImagePath) {
    return (
      <Box padding={2} height={"100%"}>
        <BaseImage
          src={problemImagePath}
          width="90%"
          maxWidth="600px"
          aspectRatio="1"
        />
        <AnswerForm
          foodOrDrink="drink"
          problemID={orderedDrinkID}
          onAnswer={(answer) => {
            if (orderedDrinkID !== undefined) {
              const targetProblems = PROBLEMS.filter((problem) => {
                return problem.id === orderedDrinkID;
              });
              if (targetProblems.length === 0) {
                notifier.notifyError(
                  "該当の問題が見つかりませんでした...店員を呼んでください。"
                );
              } else {
                const targetProblem = targetProblems[0];
                if (
                  targetProblem.answer.includes(answer) &&
                  id &&
                  customerID !== null
                ) {
                  notifier.notifySuccess("正解です！");
                  drinkUpAlcohol(
                    id,
                    customerID,
                    orderedDrinkID,
                    targetProblem.alcoholLevel
                  );
                } else {
                  notifier.notifyError("不正解です...");
                }
              }
            }
          }}
        />
      </Box>
    );
  } else {
    return <Box padding={2}>店員を呼び、ドリンクの注文をお願いします！</Box>;
  }
};
