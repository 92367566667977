import { Box, Grid } from "@mui/material";
import { useMemo } from "react";
import { useRealtimeDatabase } from "src/firebase/realtime_database/hooks";
import { IzakayaRealTimeDataBaseData } from "src/firebase/realtime_database/type";
import { TableInfo } from "./table_info";

export const OwnerTablePage = () => {
  const configData =
    useRealtimeDatabase<IzakayaRealTimeDataBaseData["config"]>("config");
  const tableData =
    useRealtimeDatabase<IzakayaRealTimeDataBaseData["table"]>("table");

  const roomArray = useMemo(
    () => [...Array(configData?.roomAmount)].map((_, i) => i),
    [configData?.roomAmount]
  );

  if (configData) {
    return (
      <Box>
        <Grid container spacing={2}>
          {roomArray.map((roomNumber) => {
            if (tableData) {
              return (
                <Grid item xs={12} key={roomNumber} lg={6} md={6}>
                  <TableInfo
                    {...tableData[roomNumber]}
                    tableID={roomNumber}
                    gameID={tableData[roomNumber]?.gameID}
                    configData={configData}
                  />
                </Grid>
              );
            } else {
              return (
                <Grid item xs={12} key={roomNumber} lg={6} md={6}>
                  <TableInfo tableID={roomNumber} configData={configData} />
                </Grid>
              );
            }
          })}
        </Grid>
      </Box>
    );
  } else {
    return <Box></Box>;
  }
};
