import { Box } from "@mui/material";
import { useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { GameAuth } from "src/components/organisms/authentication/game_auth";
import { GameFooter } from "src/components/organisms/game_footer/game_footer";
import { GameHeader } from "src/components/organisms/game_header/game_header";
import { GameID } from "src/core/game";
import { useRealtimeDatabase } from "src/firebase/realtime_database/hooks";
import { IzakayaRealTimeDataBaseData } from "src/firebase/realtime_database/type";
import { realTimeGameData } from "src/store/recoil_state";

/**
 * for react-router.
 */
export const GameTemplate = () => {
  const { id } = useParams();
  const gameData = useRealtimeDatabase<
    IzakayaRealTimeDataBaseData["game"][GameID]
  >(`game/${id}`);

  const [, setRealTimeGameData] = useRecoilState(realTimeGameData);

  useEffect(() => {
    setRealTimeGameData(gameData || {});
  }, [gameData, setRealTimeGameData]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box sx={{ flexGrow: 0, height: "auto" }}>
        <GameHeader />
      </Box>
      <Box sx={{ flexGrow: 1, overflow: "auto", height: "0px" }}>
        <GameAuth>
          <Outlet />
        </GameAuth>
      </Box>
      <Box sx={{ flexGrow: 0, height: "auto" }}>
        {/* only for spacing */}
        <Box sx={{ height: "10vh" }}></Box>
        <GameFooter gameData={gameData ?? {}} height={"10vh"} />
      </Box>
    </Box>
  );
};
