import { Button, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useModal } from "src/components/organisms/context_modal/context_modal";

export type TableNumberFormProps = {
  tableNumber?: number;
};

export const TableNumberForm = (props: TableNumberFormProps) => {
  const [memberAmount, setMemberAmount] = useState(props.tableNumber);
  const modalController = useModal();

  useEffect(() => {
    if (props.tableNumber !== undefined) {
      setMemberAmount(props.tableNumber);
    }
  }, [props.tableNumber]);

  return (
    <div style={{ position: "relative" }}>
      <TextField
        disabled={props.tableNumber === undefined}
        size="small"
        type="number"
        label="卓数"
        placeholder="卓数"
        inputProps={{ min: "1", max: "10", step: "1" }}
        value={memberAmount ?? 0}
        sx={{ minWidth: "115px", width: "50%" }}
        onChange={(e) => {
          setMemberAmount(Number(e.target.value));
        }}
      />
      <Button
        disabled={props.tableNumber === undefined}
        style={{
          // sloppy margin...
          right: "20px",
          position: "absolute",
        }}
        variant="contained"
        onClick={() => {
          modalController.open(
            <div>
              <p>変更しますか？</p>
              <Button variant="contained">はい</Button>
            </div>
          );
        }}
      >
        変更
      </Button>
    </div>
  );
};
