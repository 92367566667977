/**
 * define path for each page, because sometimes path initialization fails when we define path in each page.
 */

export const OWNER_PAGE_PATH = "/owner";
export const OWNER_TABLE_PAGE_PARTIAL_PATH = "table";
export const OWNER_TABLE_PAGE_FULL_PATH = `${OWNER_PAGE_PATH}/${OWNER_TABLE_PAGE_PARTIAL_PATH}`;
export const OWNER_GAME_PROBLEM_PAGE_PARTIAL_PATH = "problem";
export const OWNER_GAME_PROBLEM_PAGE_FULL_PATH = `${OWNER_PAGE_PATH}/${OWNER_GAME_PROBLEM_PAGE_PARTIAL_PATH}`;
export const OWNER_SETTING_PAGE_PARTIAL_PATH = "setting";
export const OWNER_SETTING_PAGE_FULL_PATH = `${OWNER_PAGE_PATH}/${OWNER_SETTING_PAGE_PARTIAL_PATH}`;
export const OWNER_TABLE_DETAIL_PAGE_PARTIAL_PATH = "table_detail";
export const OWNER_TABLE_DETAIL_PAGE_FULL_PATH = `${OWNER_PAGE_PATH}/${OWNER_TABLE_DETAIL_PAGE_PARTIAL_PATH}`;

// table
export const TABLE_PAGE_PATH = "/table";
