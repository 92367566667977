import { getDatabase, ref, set } from "@firebase/database";
import { RealtimeDatabaseTable } from "src/firebase/realtime_database/type";

export type TableID = number;

export const upsertFirebaseTable = (
  tableID: TableID,
  data: RealtimeDatabaseTable
) => {
  const db = getDatabase();
  console.debug("setTable", tableID, data);
  return set(ref(db, `table/${tableID}`), data);
};

export const cleanUpFirebaseTable = (tableID: TableID) => {
  const db = getDatabase();
  console.debug("setTable", tableID, {});
  return set(ref(db, `table/${tableID}`), {});
};
