import { Box, Button } from "@mui/material";
import { useParams } from "react-router-dom";
import { OWNER_PAGE_PATH } from "src/components/pages/owner/onwer_page";
import { GameID } from "src/core/game";
import { useRealtimeDatabase } from "src/firebase/realtime_database/hooks";
import { IzakayaRealTimeDataBaseData } from "src/firebase/realtime_database/type";

export const createOwnerGameProblemPagePath = (gameID: GameID) =>
  `${OWNER_PAGE_PATH}/game/${gameID}`;

export const OwnerGameProblemPage = () => {
  const { id }: any = useParams();

  const gameData = useRealtimeDatabase<
    IzakayaRealTimeDataBaseData["game"][GameID]
  >(`game/${id}`);

  return (
    <Box>
      {Object.keys(gameData?.problem ?? {}).map((problemID) => {
        const problem = gameData?.problem?.[problemID];

        return (
          <Button
            key={problemID}
            variant="contained"
            disabled={problem?.status === "solved"}
          >
            {problem?.id}
          </Button>
        );
      })}
    </Box>
  );
};
