import { Box, Grid } from "@mui/material";
import { rgba } from "polished";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { TableInfo } from "src/components/pages/owner/table/table_info";
import { TableID } from "src/core/table";
import { useRealtimeDatabase } from "src/firebase/realtime_database/hooks";
import { IzakayaRealTimeDataBaseData } from "src/firebase/realtime_database/type";

const sloppyColor: { [key: TableID]: string } = {
  0: "red",
  1: "aqua",
  2: "green",
  3: "yellow",
  4: "purple",
  5: "grey",
};
export const OwnerTableDetailPage = () => {
  const configData =
    useRealtimeDatabase<IzakayaRealTimeDataBaseData["config"]>("config");
  const location = useLocation();
  const query = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const tableID = useMemo<number | null>(() => {
    const tableID = query.get("tableID");
    if (tableID === null) {
      return null;
    } else {
      return Number(tableID);
    }
  }, [query]);

  const tableData = useRealtimeDatabase<
    IzakayaRealTimeDataBaseData["table"][TableID]
  >(`table/${tableID}`);

  if (tableID === null) {
    return <div>tableIDが入力されていません。</div>;
  }
  if (!configData) {
    return <div>configDataがありません。</div>;
  }
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        backgroundColor: rgba(sloppyColor[tableID], 0.1),
      }}
    >
      <Grid item xs={12} lg={6} md={6}>
        <TableInfo
          {...tableData}
          tableID={tableID}
          // gameID={.gameID}
          configData={configData}
        />
      </Grid>
    </Box>
  );
};
