import { Box, Button, TextField } from "@mui/material";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { TwitterWidget } from "src/components/molecules/twitter_button/twitter_button";
import { useModal } from "src/components/organisms/context_modal/context_modal";
import { useNotifier } from "src/components/organisms/context_snackbar/context_snackbar";
import { scoreStyle } from "src/components/organisms/game_footer/game_footer";
import { Ranking } from "src/components/organisms/ranking/ranking";
import { GameID, setGamePassword } from "src/core/game";
import { useRealtimeDatabase } from "src/firebase/realtime_database/hooks";
import { IzakayaRealTimeDataBaseData } from "src/firebase/realtime_database/type";
import { getCookie, saveCookie } from "src/store/cookie";
import { realTimeGameData } from "src/store/recoil_state";

export type GameAuthProps = {
  children: ReactNode;
};

const ModalFrame = (props: { children: ReactNode }) => {
  return (
    <Box width={"80vw"} height={"80vh"}>
      {props.children}
    </Box>
  );
};

export const GameAuth = (props: GameAuthProps) => {
  // return <>{props.children}</>;
  const modalController = useModal();
  const notifier = useNotifier();
  const { id } = useParams();

  const [authenticated, setAuthenticated] = useState<boolean>(false);

  const gameConfigData = useRealtimeDatabase<
    IzakayaRealTimeDataBaseData["game"][GameID]["config"]
  >(`game/${id}/config`);

  const password = useMemo(
    () => gameConfigData?.password,
    [gameConfigData?.password]
  );
  const gameData = useRecoilValue(realTimeGameData);
  const finished = useMemo(() => {
    return gameData.status?.progress === "finished";
  }, [gameData.status?.progress]);

  useEffect(() => {
    if (!id) return;

    // check cookie
    const cookiePassword = getCookie("teamName");
    if (
      !authenticated &&
      password !== undefined &&
      cookiePassword !== undefined
    ) {
      if (cookiePassword === password) {
        setAuthenticated(true);
        modalController.close();
        return;
      }
    }

    if (password) {
      modalController.open(
        <ModalFrame>
          <PasswordConfirmationForm
            gameID={id}
            password={password}
            setAuthenticated={setAuthenticated}
          />
        </ModalFrame>
      );
    } else {
      modalController.open(
        <ModalFrame>
          <PasswordCreateForm gameID={id} setAuthenticated={setAuthenticated} />
        </ModalFrame>
      );
    }
    if (authenticated) {
      modalController.close();
    }
  }, [authenticated, id, modalController, notifier, password]);

  useEffect(() => {
    if (authenticated) {
      modalController.close();
    }
  }, [authenticated, modalController]);

  if (id === undefined) {
    return <div>id is undefined</div>;
  }
  if (finished) {
    return <ThankYouForPlaying />;
  }
  if (authenticated) {
    return <>{props.children}</>;
  } else {
    return <></>;
  }
};

const ThankYouForPlayingNaiveBox = (props: { children: ReactNode }) => {
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      flexDirection={"column"}
      width={"95%"}
      sx={{
        paddingLeft: "3vw",
        paddingRight: "3vw",
        paddingTop: "2vh",
        paddingBottom: "2vh",
      }}
    >
      {props.children}
    </Box>
  );
};

const ThankYouForPlaying = () => {
  const gameData = useRecoilValue(realTimeGameData);
  return (
    <Box
      width={"100%"}
      height={"100%"}
      display={"flex"}
      p={1}
      flexDirection={"column"}
      // justifyContent={"center"}
      alignItems={"center"}
    >
      <ThankYouForPlayingNaiveBox>
        <div>この度はご来店ありがとうございました！</div>
        <div>
          居酒屋桐蔵はこれからもお客様に楽しんでいただけるように努めてまいります。
        </div>
        <div>よろしければXの投稿とアンケートをお願いします！</div>
      </ThankYouForPlayingNaiveBox>
      <ThankYouForPlayingNaiveBox>
        <TwitterWidget score={gameData.status?.score ?? 0} />
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLScB464_RO3ueNafYGd1Nu8kIqYNOnMXGVQP7uG4PJHKOTnbTQ/viewform"
          target="_blank"
          rel="noreferrer"
        >
          アンケート
        </a>
      </ThankYouForPlayingNaiveBox>

      <ThankYouForPlayingNaiveBox>
        <h3>チーム {gameData.config?.password} 様のスコア</h3>
        <div style={scoreStyle}>{gameData.status?.score}</div>
      </ThankYouForPlayingNaiveBox>
      <ThankYouForPlayingNaiveBox>
        <h3>ランキング</h3>
        <Ranking />
      </ThankYouForPlayingNaiveBox>
    </Box>
  );
};

const PasswordConfirmationForm = (props: {
  gameID: GameID;
  password: string;
  setAuthenticated: (authenticated: boolean) => void;
}) => {
  const [inputPassword, setInputPassword] = useState<string | undefined>(
    undefined
  );

  const notifier = useNotifier();
  const modalController = useModal();

  return (
    <div>
      この度はご来店ありがとうございます！ チーム名の入力をお願いします
      <Box display={"flex"} justifyContent={"center"}>
        <Box display={"flex"} flexDirection={"column"} margin={2}>
          <TextField
            label="チーム名"
            margin={"normal"}
            placeholder="10文字以内"
            inputProps={{
              maxLength: 10,
            }}
            value={inputPassword}
            color="secondary"
            onChange={(e) => {
              setInputPassword(e.target.value);
            }}
          />
          <Button
            color="secondary"
            variant={"contained"}
            onClick={() => {
              if (inputPassword === props.password) {
                notifier.notifySuccess("認証されました！");
                props.setAuthenticated(true);
                modalController.close();
                saveCookie("teamName", inputPassword, 1);
              } else {
                notifier.notifyError("チーム名が違うようです...");
              }
            }}
          >
            確認
          </Button>
        </Box>
      </Box>
    </div>
  );
};

const PasswordCreateForm = (props: {
  gameID: GameID;
  setAuthenticated: (authenticated: boolean) => void;
}) => {
  const [password, setPassword] = useState<string | undefined>(undefined);
  const notifier = useNotifier();
  const modalController = useModal();
  return (
    <div style={{ width: "100%", height: "100%" }}>
      この度はご来店ありがとうございます！
      はじめにこのテーブルのチーム名を決めてください。
      <Box display={"flex"} justifyContent={"center"}>
        <Box display={"flex"} flexDirection={"column"} margin={2}>
          <TextField
            margin={"normal"}
            label="チーム名"
            placeholder="10文字以内"
            value={password}
            inputProps={{
              maxLength: 10,
            }}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          <Button
            variant="contained"
            onClick={() => {
              if (password) {
                props.setAuthenticated(true);
                setGamePassword(props.gameID, password);
                notifier.notifySuccess(`チーム名を設定しました。 ${password}`);
                saveCookie("teamName", password, 1);
                modalController.close();
              }
            }}
          >
            送信
          </Button>
        </Box>
      </Box>
    </div>
  );
};
