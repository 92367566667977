import { useEffect } from "react";

export const TwitterWidget = (props: { score: number }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://platform.twitter.com/widgets.js";
    script.charset = "utf-8";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <div className="twitter-widget-container">
      <a
        href="https://twitter.com/share?ref_src=twsrc%5Etfw"
        className="twitter-share-button"
        data-text={`あなたは居酒屋桐蔵で${props.score}杯飲み干しました！`}
        data-hashtags="謎解き居酒屋,きりんぐたいむに象の群れ,なぞすぺ"
        data-show-count="false"
        data-lang="en"
        data-size="large"
        data-url=" "
      >
        Tweet
      </a>
    </div>
  );
};
